/* LoadingCard.css */
.loading-card {
  width: 100%; /* Adjust as needed */
  height: 380px; /* Adjust to match your ItemCard size */
  background: #f6f7f8;
  border-radius: var(--border-radius);
  overflow: hidden;
  position: relative;
}

.loading-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: -150%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent 0%, #e0e0e0 50%, transparent 100%);
  animation: loading-card 1.5s infinite;
}

.loading-image {
  width: 100%;
  height: 70%;
  background: #e0e0e0;
}

.loading-text {
  width: 80%;
  height: 20px;
  margin: 10px;
  background: #e0e0e0;
}

.loading-text.small {
  width: 60%;
}

@keyframes loading-card {
  to {
    left: 100%;
  }
}
