.modal .row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-block-end: 1rem;
  margin-inline: 1rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35); /* Black with 50% opacity */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensures overlay is above other content */
}

.modal {
  background-color: #fff; /* White background */
  width: 400px; /* Adjust width as per your requirement */
  position: relative;
  z-index: 1001; /* Ensures modal is above the overlay */
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: var(--border-radius);
  margin: 1rem;
  padding: .5rem;
}

.modal h3 {
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.close-button {
  background-color: transparent;
  border: none;
  font-size: 20px;
  color: black;
  cursor: pointer;
  padding: 0;
  align-self: flex-start;
}

/* Optional: Style hover and focus state for better UX */
.close-button:hover,
.close-button:focus {
  color: red;
}

.email-input {
  width: 100%;
  padding-inline: 10px;
  border: 2px solid #ccc;
  border-start-start-radius: var(--border-radius);
  border-end-start-radius: var(--border-radius);
  font-size: 1rem;
  padding: .95rem 1.7rem;
}

.send-button {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

/* Additional: You may want to style the input field for focus state for better UX */
.email-input:focus {
  border-color: #4A90E2;
  outline: none;
}
