.itemHeader {
  display: flex;
  margin-block-end: 2rem;
}

.headingImage {
  background: black;
  width: 20rem;
}

.itemsInfo {
  align-self: flex-end;
  margin-inline-start: 2rem;
}

.itemsInfo h1,
.itemsInfo p {
  margin: 0;
}

.noneFound {
  text-align: center;
}
