header button.logo {
  background: none;
  color: var(--color-dark);
}

header button.login {
  background: none;
  color: var(--color-dark);
}


header button.login:hover {
  text-decoration: underline;
}

header {
  display: flex;
  justify-content: space-between;
  box-shadow: 1px 1px 1px var(--color-lightish);
  background-color: white;
}
