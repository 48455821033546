.reel {
  display: flex;
  block-size: auto;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-color: #fff #000;
  padding-block-start: 1rem;
  padding-inline-end: 1rem;
}

.reel > * {
  flex: 0 0 auto;
}

.reel > img {
  block-size: 100%;
  flex-basis: auto;
  width: auto;
}

.reel > * + * {
  margin-inline-start: 1rem;
}

.reel.overflowing {
  padding-block-end: 1rem;
}
