.claim {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}

.claimContainer.cent {
  display: block;
}

.claimContainer {
  text-align: center;
  display: flex;
  background: white;
  padding: 2rem;
  align-items: center;
  justify-content: center;
}

.infoContainer {
  justify-content: center;
  align-items: center;
}

.imageContainer {
  position: relative;
  height: 350px;
  width: 350px;
  overflow: hidden;
}

.imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.claimContainer h2 {
  text-decoration: underline;
  margin-block-end: .25rem;
}

.claimContainer button {
  margin-block-start: 2rem;
  width: fit-content;
  align-self: center;
}

.claimContainer form label {
  text-align: left;
  font-size: 1rem;
}

.claimContainer form input,
.claimContainer form span {
  background-color: var(--color-lightish);
  padding: .5rem;
  border: none;
  font-size: 1rem;
  text-align: left;
  overflow-wrap: anywhere;
}

.loadingGradient {
  width: 350px;
  height: 350px;
}
