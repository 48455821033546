.box {
  padding: var(--s1);
  border: var(--border-thin) solid;
  color: var(--color-dark);
  background-color: var(--color-light);
  border-radius: var(--border-radius);
}

.box.invert {
  color: var(--color-light);
  background-color: var(--color-dark);
}
