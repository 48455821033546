.create {
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  margin-inline: auto;
}

.details {
  font-size: small;
}

.creatorHeader,
.creatorStatus {
  background-color: white;
  padding: 1.5rem;
  border-radius: var(--border-radius);
}

h2 {
  margin: 0;
}

h3 {
  margin-block: 0;
}

svg.copyIcon,
svg.linkIcon,
svg.audioIcon {
  margin-inline-start: .5rem;
}

svg.copyIcon:hover,
svg.linkIcon:hover,
svg.audioIcon:hover {
  cursor: pointer;
}

svg.copyIcon:active,
svg.linkIcon:active,
svg.audioIcon:active,
button:active {
  transform: scale(.98);
}

.creatorStatus  {
  margin-block-start: 1rem;
}

.addUser {
  text-align: center;
}

.actionButtons {
  margin-block-start: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: baseline;
}

.actionButtons button {
  font-size: small;
  text-wrap: nowrap;
  padding: .5rem;
}

.buttonSection {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

@media (max-width: 49rem) { /* Adjust this based on when .switcher toggles */
  .buttonSection {
    justify-content: center; /* center alignment when .switcher toggles */
  }
}

select {
  margin-block-end: 1rem;
}

.collectionHeader {
  display: flex;
  justify-content: space-between;
}

.buttonDisabled {
  opacity: .35;
  cursor: not-allowed;
}

.buttonDisabled:active {
  transform: scale(1);
}

.audioPlayerContainer {
  margin-block-start: 1rem;
  display: flex;
  justify-content: center;
}
