:root {
  --font-size: calc(1rem + 0.2vw);
  --measure: 60ch;
  --ratio: 1.61803398875;

  --s0: 1rem;
  --s1: calc(var(--s0) * var(--ratio));
  --s2: calc(var(--s1) * var(--ratio));
  --s3: calc(var(--s2) * var(--ratio));
  --s4: calc(var(--s3) * var(--ratio));
  --s5: calc(var(--s4) * var(--ratio));

  --s-1: calc(var(--s0) / var(--ratio));
  --s-2: calc(var(--s-1) / var(--ratio));
  --s-3: calc(var(--s-2) / var(--ratio));
  --s-4: calc(var(--s-3) / var(--ratio));
  --s-5: calc(var(--s-4) / var(--ratio));

  --border-thin: var(--s-4);
  --border-thick: var(--s-2);

  --line-height: var(--ratio);

  --color-light: #fafafa;
  --color-lightish: #e6e6e6;
  --color-lightish-o:rgba(230, 230, 230, .35);

  --color-dark: #050505;
  --color-dark-o: #0505058c;
  --color-darkish: #404040;

  --color-mid: grey;

  --color-primary:  linear-gradient(45deg, #045be8, #2302dc);
  --color-primary-d: #045be8;
  --color-primary-o: #2302dc;

  --border-radius: .15rem;

  --divider-color: lightgrey;
  --divider-hover-color: white;
  --divider-focused-color: var(--color-lightish);

  --font-family-main: 'Noto Sans', sans-serif;

  --neutral-color: #f1f1e9;
  --white-color: #ffffff;
  --dark-color: #37392d;
  --pine-color: #354333;
  --blue-color: #daf0ff;
  --salmon-color: #e78f79;
  --yellow-color: #ffffcf;
  --brand-color: #8fff95;
  --lightgreen-color: #e1f2c9;
}

@keyframes shimmer {
  0% {
    background-position: -40rem 0;
  }
  100% {
    background-position: 40rem 0;
  }
}

.loading-gradient {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 80rem 10rem;
}
