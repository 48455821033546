.library {
  margin-inline: auto;
}

.loginPrompt {
  text-align: center;
}

.nav {
  margin-block-end: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: .5rem;
}

.nav button {
  background: none;
  color: var(--color-dark);
  padding: 0;
}

.nav button.active {
  text-decoration: underline;
}
