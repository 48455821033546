.cover {
  display: flex;
  flex-direction: column;
  min-block-size: 100vh;
}

.cover > * {
  margin-block: 2rem;
}

.cover > :first-child:not(main) {
  margin-block-start: 0;
}

.cover > :last-child:not(main) {
  margin-block-end: 0;
}

.cover > main {
  margin-block-start: 0;
  margin-block-end: auto;
}
