.item-card {
  position: relative;
  background-color: white;
  border: 2px black solid;
  filter: drop-shadow(0px 0px 2px var(--color-dark));
}

.item-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item-card .image {
  position: relative;
  height: 300px; /* Fixed height for the image container */
  overflow: hidden;
}

.item-card.loading {
  /* Styles to indicate loading, e.g., reduced opacity, placeholder background, etc. */
  opacity: 0.5;
}
