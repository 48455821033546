svg.copyIcon,
svg.linkIcon {
  margin-inline-start: .5rem;
}

svg.copyIcon:hover,
svg.linkIcon:hover {
  cursor: pointer;
}

svg.copyIcon:active,
svg.linkIcon:active {
  transform: scale(.9);
}

.headline {
  font-size: small;
  display: block;
  margin-block-start: 1rem;
}

.accountInfo {
  background-color: var(--color-light);
  padding: 1.5rem;
  border-radius: var(--border-radius);
}

h2 {
  margin-block-start: 0;
}
