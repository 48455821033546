.loadingGradient {
  height: 50px;
}

.audioHeader {
  display: flex;
  margin-block-end: 2rem;
}

.headingImage {
  background: black;
  width: 20rem;
  filter: drop-shadow(0px 0px 1px var(--color-dark));
}

.audioInfo {
  align-self: flex-end;
  margin-inline-start: 2rem;
}

.audioInfo h1,
.audioInfo p {
  margin: 0;
}

.audioNav {
  display: flex;
  justify-content: space-between;
  margin-block-end: 2rem;
}

.playAllButton {
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  background: var(--color-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.playAllButton svg {
  padding-inline-start: .2rem;
  fill: white;
}

.audioTableContainer {
  margin-block-end: 2rem;
}

.audioTable {
  width: 100%;
}

.audioTable th {
  text-align: left;
}

.audioRow {
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

.playIconHover,
.pauseIconHover {
  display: none;
}

.audioRow:hover .playIconHover,
.audioRow:hover .pauseIconHover {
  display: block;
}

.audioRow:hover .number {
  display: none;
}

.numberSpinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}

.number {
  font-size: 1rem;
}

.audioRow .playIcon .loading-spinner {
  position: absolute;
  transform: translate(0%, -50%);
}

.audioDetail {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.audioImage {
  width: 2rem;
}

.audioInfo {
  display: flex;
  flex-direction: column;
}

.artist {
  font-size: 0.75rem;
}

.audioPlayerContainer {
  display: flex;
  justify-content: center;
  margin-block-end: 1rem;
}

.active {
  color: rgb(3, 116, 116);
}
