@media (max-width: 1400px) {
  .header, main {
    margin-inline: var(--s3) !important;
  }
}

@media (max-width: 1100px) {
  .header, main {
    margin-inline: var(--s1) !important;
  }
}

@media (min-width: 1100px) {
  .library, .lock, .m1rror {
    max-width: 1100px;
  }
}

@media (max-width: 400px) {
  main {
    margin-inline: var(--s0) !important;
  }
}

@media (max-width: 768px) {
  .hm-wrapper,
  .v-footer {
    display: flex;
  }

  .nav,
  .login,
  .account-dropdown,
  .h-footer {
    display: none;
  }
}
