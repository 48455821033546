footer {
  font-size: .83rem;
}

.h-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-block-start: var(--s-5) solid var(--color-lightish);
  background-color: white;
  padding-inline: 2rem;
}

.h-footer p {
  margin-inline-end: .5rem;
}

.footer-l {
  display: flex;
  align-items: center;
}

.footer-r {
  display: flex;
}

.v-footer {
  display: none;
  border-block-start: var(--s-5) solid var(--color-lightish);
  justify-content: center;
  text-align: center;
  background-color: white;
}

.v-footer ul {
  list-style: none;
  padding-inline-start: 0;
}
