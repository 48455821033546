nav {
  background-color: transparent;
  display: flex;
  flex-wrap: wrap;
  gap: var(--space, 3rem);
  justify-content: flex-start;
  align-items: center;
}

a.nav-item {
  color: var(--color-dark);
}

a.nav-item:hover {
  color: var(--color-primary);
}
