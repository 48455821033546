.account-dropdown .account-btn {
  background-color: white;
  color: var(--color-dark);
  display: flex;
  align-items: center;
}

.account-dropdown .account-btn svg:hover {
  background-color: white;
  border-radius: 50%;
  filter: drop-shadow(0px 0px 2px var(--color-dark-o));
}

/* .account-dropdown .account-btn svg,
.dropdown-menu svg.copy-icon,
.dropdown-menu svg.link-icon {
  fill: var(--color-dark);
} */

.dropdown-menu svg.copy-icon,
.dropdown-menu svg.link-icon {
  margin-inline-start: .5rem;
}

.dropdown-menu svg.copy-icon:hover,
.dropdown-menu svg.link-icon:hover {
  cursor: pointer;
}

.dropdown-menu svg.copy-icon:active,
.dropdown-menu svg.link-icon:active {
  transform: scale(.9);
}

.account-dropdown .logout-btn {
  background-color: white;
  color: var(--color-dark);
  font-size: .95rem;
  text-align: left;
}

.account-dropdown .dropdown-menu {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 3.5rem;
  right: 0;
  background-color: white;
  z-index: 1;
  color:var(--color-dark);
  filter: drop-shadow(0px 0px 2px var(--color-dark-o));
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  font-size: .95rem;
}

.account-dropdown .dropdown-item {
  padding-block: .5rem;
  padding-inline: 1.25rem;
  padding-inline-end: 6rem;
  color: var(--color-dark);
}
