.m1rror {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}

.m1rrorOuter {
  flex-direction: column;
}

.m1rrorContainer {
  text-align: center;
  display: flex;
  background: white;
  padding: 2rem;
  align-items: center;
  justify-content: center;
}

.imageContainer {
  position: relative;
  height: 350px;
  width: 350px;
  overflow: hidden;
}

.imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.m1rrorContainer h2 {
  text-decoration: underline;
  margin-block-end: .25rem;
}

.m1rrorContainer.item h2 {
margin-block-end: revert;
}

.m1rrorContainer ul {
  list-style: none;
  padding-inline-start: 0;
}

.m1rrorContainer ol {
  padding-inline-start: 0;
}

.m1rrorContainer li:first-child {
  margin-block-start: .5rem;
}

.m1rrorContainer li {
  margin-inline-start: 2rem;
  font-size: 1rem;
}

.m1rrorItemDetails li {
  display: flex;
  gap: .25rem;
}

.trackDetailList li {
  display: flex;
  gap: .25rem;
}

.m1rrorContainer.item {
  flex-direction: column;
  margin-block-end: 2rem;
}

.m1rrorContainer button {
  margin-block-start: 2rem;
  width: fit-content;
  align-self: center;
}

.m1rrorItemDetails,
.m1rrorTrackDetails {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.m1rrorTrackDetails .trackDetails {
  flex-grow: 1;
}

.loadingGradient {
  width: 350px;
  height: 350px;
}

.loadingLi {
  width: 15rem;
  height: 1.5rem;
}

.itemInst li {
  display: list-item;
}

li.trackInst  {
  margin-block-start: 0 !important;
  list-style: lower-alpha;
  margin-inline-start: 4rem;
}
