.formBtns {
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin-block-start: 2rem;
}

.collectionForm {
  margin-block-start: 1rem;
  background-color: var(--color-light);
  padding: 1.5rem;
  border-radius: var(--border-radius);
}

.collectionFormBody {
  display: flex;
  justify-content: center;
}
