.tokensTable {
  width: 100%;
  text-align: left;
}

svg.playIcon:hover,
svg.pauseIcon:hover,
svg.uploadIcon:hover {
  cursor: pointer;
}

svg.playIcon:active,
svg.pauseIcon:active,
svg.uploadIcon:active {
  transform: scale(.98);
}
