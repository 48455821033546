.hm-wrapper {
  display: none;
}

.hamburger-menu {
  position: relative;
  display: flex;
  align-self: center;
  border: 2px solid var(--color-dark);
  border-radius: var(--border-radius);
  padding:.5rem;
  height: fit-content;
  z-index: 100;
}

.hamburger-menu:hover, .hamburger-menu.active {
  background-color: white;
  filter: drop-shadow(0px 1px 2px var(--color-dark-o));
}

.hamburger-icon {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: .75rem;
  min-width: 1rem;
}

.hamburger-icon .line {
  background-color: var(--color-dark);
  height: 2px;
  width: 100%;
  transition: all 0.3s ease-out;
}

.hamburger-icon .line-open:first-child {
  transform: rotate(45deg);
  background: var(--color-dark);
  translate: 0 .32rem;
}

.hamburger-icon .line-open.mid {
  background: transparent;
}

.hamburger-icon .line-open:last-child {
  transform: rotate(-45deg);
  background-image: var(--color-dark);
  translate: 0 -.31rem;
}

.hamburger-menu .menu-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 2.5rem;
  right: 0;
  background-color: white;
  z-index: 1;
  color:var(--color-dark);
  filter: drop-shadow(0px 0px 2px var(--color-dark-o));
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  font-size: .95rem;
}

.hamburger-menu .menu-item {
  padding-block: .75rem;
  padding-inline: 1.25rem;
  padding-inline-end: 6rem;
  cursor: pointer;
  color: var(--color-dark);
}

.hamburger-menu .menu-item:last-child {
  border-bottom: none;
}

.hamburger-menu .menu-item:hover {
  color: var(--color-dark);
}

.hamburger-menu:hover .line {
  background-image: var(--color-dark);
}

.hamburger-menu:hover .line-open.mid {
  background: transparent;
}

.login-hm,
.logout-hm {
  background: white;
  color: var(--color-dark);
  font-size: .95rem;
  padding-block: 0.75rem;
  padding-inline: 1.25rem;
  text-align: left;
}

.login-hm:hover {
  text-decoration: underline;
}

.account-info-hm .account-item {
  padding-block: .35rem;
  padding-inline: 1.25rem;
  padding-inline-end: 2rem;
  text-wrap: nowrap;
  font-size: .75rem;
}

.hamburger-menu .copy-icon {
  margin-inline: .5rem;
}

.account-info-hm {
  border-block-end: 1px var(--color-lightish) solid;
}
