.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal-content {
  background-color: white;
  padding: 1.5rem;
  border-radius: var(--border-radius);
  filter: drop-shadow(0px 0px 2px var(--color-dark-o));
  max-width: 80%;
  max-height: 80%;
  position: relative;
  overflow: auto;
}

.modal-content #modal-close {
  margin-block-start: 0;
  max-width: fit-content;
  cursor: pointer;
}
